// For Organizer
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import VueGtm from '@gtm-support/vue2-gtm'

const CreateNewEvent = () => import( '@/components/CreateEvent/CreateNewEvent' )
const EditMyPage = () => import( '@/components/EditMyPage/EditMyPage' )
const MailHistory = () => import( '@/components/MailHistory' )
const MailDetail = () => import( '@/components/MailDetail' )
const FileShare = () => import( '@/components/FileShare' )
const RegistrationStatus = () => import( '@/components/RegistrationStatus' )
const TicketSales = () => import( '@/components/TicketSales' )
const VisitorLogs = () => import('@/components/VisitorLogs.vue')
const CancelInformation = () => import( '@/components/CancelInformation' )
const EventSchedule = () => import( '@/components/EventSchedule' )
const EventScheduleList = () => import( '@/components/EventScheduleList' )
const BundleSiteList = () => import( '@/components/bundlesite/BundleSiteList' )
const CreateNewBundleSite = () => import( '@/components/bundlesite/create/CreateNewBundleSite' )
const Signin = () => import( '@/components/Signin' )
const Signout = () => import( '@/components/Signout' )
const CreateEmail = () => import( '@/components/CreateEmail' )
const PasswordReset = () => import( '@/components/PasswordReset' )
const PasswordSetting = () => import( '@/components/PasswordSetting' )
const Publish = () => import( '@/components/Publish' )
const OperationLog = () => import( '@/components/OperationLog' )
const TermsOfServicePage = () => import( '@/components/TermsOfServicePage' )
const IndividualTermsForPaymentPage = () => import( "../components/individualterms/fororganizer/IndividualTermsForPaymentPage.vue" )
const SquareAccounts = () => import( '@/components/square/SquareAccounts' )
const SquareAccountGrantStatus = () => import( '@/components/square/SquareAccountGrantStatus' )
const SquareOAuthBridge = () => import( '@/components/square/SquareOAuthBridge' )
const SquareOAuthReceiver = () => import( '@/components/square/SquareOAuthReceiver' )
const Group = () => import( '@/components/Group' )
const Invitation = () => import( '@/components/Invitation' )
const SharedVideo = () => import( '@/components/sharedvideo/SharedVideo' )
const Survey = () => import( '@/components/survey/SurveyList' )
const DoLandingSurvey = () => import( '@/components/survey/DoLandingSurvey' )
const Quiz = () => import( '@/components/quiz/QuizList' )
const DoLandingQuiz = () => import( '@/components/quiz/DoLandingQuiz' )
const ZoomAccounts = () => import( '@/components/zoom/ZoomAccounts' )
const ZoomGrantStatus = () => import( '@/components/zoom/ZoomGrantStatus' )
const ZoomReflectionStatus = () => import( "@/components/zoom/ZoomReflectionStatus" )
const CustomMailTemplates = () => import( "../components/custommailtemplate/CustomMailTemplates" )
const ReturnPolicies = () => import( "../components/returnpolicies/ReturnPolicies" )
const ReturnPolicyEdit = () => import( "../components/returnpolicies/ReturnPolicyEdit" )
const ServerOauthCheckLoggedIn = () => import( "../components/server/oauth/CheckLoggedIn" )
const ServerOauthSignin = () => import( "../components/server/oauth/Signin" )

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Signin',
      component: Signin
    },
    {
      path: '/Signout',
      name: 'Signout',
      component: Signout
    },
    {
      path: '/CreateNewEvent',
      name: 'CreateNewEvent',
      component: CreateNewEvent
    },
    {
      path: '/EditMyPage',
      name: 'EditMyPage',
      component: EditMyPage
    },
    {
      path: '/MailHistory',
      name: 'MailHistory',
      component: MailHistory
    },
    {
      path: '/MailDetail',
      name: 'MailDetail',
      component: MailDetail
    },
    {
      path: '/FileShare',
      name: 'FileShare',
      component: FileShare
    },
    {
      path: '/RegistrationStatus',
      name: 'RegistrationStatus',
      component: RegistrationStatus
    },
    {
      path: '/TicketSales',
      name: 'TicketSales',
      component: TicketSales
    },
    {
      path: '/VisitorLogs',
      name: 'VisitorLogs',
      component: VisitorLogs
    },
    {
      path: '/CancelInformation',
      name: 'CancelInformation',
      component: CancelInformation
    },
    {
      path: '/EventSchedule',
      name: 'EventSchedule',
      component: EventSchedule
    },
    {
      path: '/EventScheduleList',
      name: 'EventScheduleList',
      component: EventScheduleList
    },
    {
      path: '/BundleSiteList',
      name: 'BundleSiteList',
      component: BundleSiteList
    },
    {
      path: '/CreateNewBundleSite',
      name: 'CreateNewBundleSite',
      component: CreateNewBundleSite
    },
    {
      path: '/CreateEmail',
      name: 'CreateEmail',
      component: CreateEmail
    },
    {
      path: '/PasswordReset',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/PasswordSetting',
      name: 'PasswordSetting',
      component: PasswordSetting
    },
    {
      path: '/Publish',
      name: 'Publish',
      component: Publish
    },
    {
      path: '/OperationLog',
      name: 'OperationLog',
      component: OperationLog
    },
    {
      path: '/TermsOfService',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage
    },
    {
      path: '/IndividualTermsForPayment',
      name: 'IndividualTermsForPaymentPage',
      component: IndividualTermsForPaymentPage
    },
    {
      path: '/SquareAccounts',
      name: 'SquareAccounts',
      component: SquareAccounts
    },
    {
      path: '/SquareAccountGrantStatus',
      name: 'SquareAccountGrantStatus',
      component: SquareAccountGrantStatus
    },
    {
      path: '/SquareOAuthBridge',
      name: 'SquareOAuthBridge',
      component: SquareOAuthBridge
    },
    {
      path: '/SquareOAuthReceiver',
      name: 'SquareOAuthReceiver',
      component: SquareOAuthReceiver
    },
    {
      path: '/Group',
      name: 'Group',
      component: Group
    },
    {
      path: '/Invitation',
      name: 'Invitation',
      component: Invitation
    },
    {
      path: '/SharedVideo',
      name: 'SharedVideo',
      component: SharedVideo
    },
    {
      path: '/Survey',
      name: 'Survey',
      component: Survey
    },
    {
      path: '/DoLandingSurvey',
      name: 'DoLandingSurvey',
      component: DoLandingSurvey
    },
    {
      path: '/Quiz',
      name: 'Quiz',
      component: Quiz
    },
    {
      path: '/DoLandingQuiz',
      name: 'DoLandingQuiz',
      component: DoLandingQuiz
    },
    {
      path: '/ZoomAccounts',
      name: 'ZoomAccounts',
      component: ZoomAccounts
    },
    {
      path: '/ZoomGrantStatus',
      name: 'ZoomGrantStatus',
      component: ZoomGrantStatus
    },
    {
      path: '/ZoomReflectionStatus',
      name: 'ZoomReflectionStatus',
      component: ZoomReflectionStatus
    },
    {
      path: '/CustomMailTemplates',
      name: 'CustomMailTemplates',
      component: CustomMailTemplates
    },
    {
      path: '/ReturnPolicies',
      name: 'ReturnPolicies',
      component: ReturnPolicies
    },
    {
      path: '/ReturnPolicyEdit',
      name: 'ReturnPolicyEdit',
      component: ReturnPolicyEdit
    },
    {
      path: '/server/oauth/CheckLoggedIn',
      name: 'ServerOauthCheckLoggedIn',
      component: ServerOauthCheckLoggedIn
    },
    {
      path: '/server/oauth/SignIn',
      name: 'ServerOauthSignIn',
      component: ServerOauthSignin
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }; // トップに移動
  }
})

import gtmCommon from '../mixins/gtmCommon'
Vue.mixin(gtmCommon)
let gtmIds = gtmCommon.methods.getMemberGtmIds()

export default router

Vue.use(VueGtm, {
  id: gtmIds.gtmId, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    gtm_auth: gtmIds.gtmAuth,
    gtm_preview: gtmIds.gtmPreview,
    gtm_cookies_win: 'x',
  },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }
)
