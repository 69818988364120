<template>
  <v-dialog scrollable v-model="showSelf" max-width="700px">
    <v-card>
      <v-card-title class="modalHeader">
        <span class="headline">お知らせ</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="text-left">
              <div class="announcement_container" v-if="announcements.length" v-for="item in announcements">
                <div class="announcement_title">
                  <span class="open_date">{{formatDate(item.openDateTime)}}</span>{{item.title}}
                </div>
                <div class="announcement_content">
                  <span>{{item.content}}</span>
                </div>
              </div>
              <div class="announcement_container" v-if="announcements.length == 0">
                <div class="no_announcement">
                  現在、お知らせはありません。
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="edit-b" @click="closeSelf()">閉じる</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import vuetifyUtil from "@/mixins/vuetifyUtil";
  import dayjs from "dayjs"
  import 'dayjs/locale/ja'
  import {getAllOpenAnnouncements} from '@/APIUtils'
  export default {
    name: "AnnouncementDialog",
    mixins: [vuetifyUtil],
    data() {
      return {
        showSelf: false,

        announcements: {}
      }
    },
    methods: {
      open() {
        getAllOpenAnnouncements().then(res => {
          this.announcements = res.data
          this.showSelf = true
        })
      },
      closeSelf() {
        this.moveToDialogTop()
        this.showSelf = false
      },
      formatDate(date) {
        return dayjs(date).format('YYYY/M/D')
      }
    },
    beforeCreate() {
      dayjs.locale('ja')
    }
  }
</script>

<style scoped>

  .modalHeader{
    background-color: #263238;
    color: white;
    height: 70px;
    font-size:160%;
    font-weight:bold;
    filter:alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
  }
  .announcement_container:nth-child(n+2) {
    margin-top: 1.5em;
  }
  .announcement_container:last-child {
    margin-bottom: 2em;
  }
  .announcement_title {
    text-align: left;
    font-weight: bold;
    font-size: 1.3em;
  }
  .announcement_title .open_date {
    margin-right: 1em;
  }
  .announcement_content {
    margin-top: 1em;
  }
  .announcement_content span {
    text-align: left;
    white-space: pre-wrap;
  }
  .no_announcement {
    text-align: center;
  }

</style>
