<template>
<v-app>
  <organizer_edit_dialog ref="organizerEditDialog" @completeOrganizerEdit="onCompleteOrganizerEdit"></organizer_edit_dialog>
  <v-app-bar
    height="65px"
    app
    dense
    color="white"
  >
    <a stye="text-align:left;" @click="moveToEventSchedule">
      <img :src="logo" class="headerLogo" >
    </a>
    <v-spacer></v-spacer>
      <v-btn
        v-if="loggedin"
        class="hidden-sm-and-down"
        depressed
        tile
        color="white"
      >
        {{userInfo.affiliation}}
      </v-btn>
      <v-menu v-if="loggedin" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            v-on="on"
            depressed
            tile
            color="white"
          >
            {{name}}
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group>
            <v-list-item
              @click="openOrganizerUpdateDialog"
            >
              <v-list-item-content>
                <v-list-item-title>
                  ユーザ情報編集
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="moveToSquareAccounts"
              v-if="isEnablePaymentSettings"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Square使用設定
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="moveToZoomAccounts"
              v-if="role==='Owner' && hasOptionZoom"
            >
              <v-list-item-content>
                <v-list-item-title>
                  ZOOM使用設定
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="moveToCustomMailTemplates"
              v-if="isEditableRole(role)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  カスタムメールテンプレート
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="moveToReturnPolicies"
              v-if="hasOptionPayment && isEditableRole(role)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  返品特約に関する表記
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="moveToOperationLog"
              v-if="isEditableRole(role)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  操作履歴
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="moveToTermsOfService"
            >
              <v-list-item-content>
                <v-list-item-title>
                  利用規約
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasOptionPayment"
              @click="moveToIndividualTermsForPayment"
            >
              <v-list-item-content>
                <v-list-item-title>
                  個別規約
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="logOut"
            >
              <v-list-item-content>
                <v-list-item-title>
                  ログアウト
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn
        class="hidden-sm-and-down"
        @click="openAnnouncementDialog"
        depressed
        tile
        color="white"
      >
        お知らせ<span v-if="existOpenAnnouncement">({{lastOpenAnnouncementDate}} 更新)</span>
      </v-btn>
      <announcement_dialog ref="announcementDialog"></announcement_dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down"
            icon
            @click.stop="openHelpPage"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>help</v-icon>
          </v-btn>
        </template>
        <span>ヘルプ</span>
      </v-tooltip>
      <v-menu
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-md-and-up"
            v-on="on"
            depressed
            tile
            color="white"
          >
            <v-icon color="black">more_vert</v-icon>
          </v-btn>
        </template>

        <v-list>
        <v-list-item-group>
          <v-list-item v-if="loggedin">
            <v-list-item-content>
              <v-list-item-title>
                {{userInfo.affiliation}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="loggedin">
            <v-list-item-content>
              <v-list-item-title>
                {{name}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="loggedin"
            @click="openOrganizerUpdateDialog"
          >
            <v-list-item-content>
              <v-list-item-title>
                ユーザ情報編集
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="moveToSquareAccounts"
            v-if="loggedin && isEnablePaymentSettings"
          >
            <v-list-item-content>
              <v-list-item-title>
                Square使用設定
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="moveToZoomAccounts"
            v-if="loggedin && role==='Owner' && hasOptionZoom"
          >
            <v-list-item-content>
              <v-list-item-title>
                ZOOM使用設定
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="moveToCustomMailTemplates"
            v-if="loggedin && isEditableRole(role)"
          >
            <v-list-item-content>
              <v-list-item-title>
                カスタムメールテンプレート
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="moveToReturnPolicies"
            v-if="loggedin && hasOptionPayment && isEditableRole(role)"
          >
            <v-list-item-content>
              <v-list-item-title>
                返品特約に関する表記
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="loggedin && isEditableRole(role)"
            @click="moveToOperationLog"
          >
            <v-list-item-content>
              <v-list-item-title>
                操作履歴
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="loggedin"
            @click="moveToTermsOfService"
          >
            <v-list-item-content>
              <v-list-item-title>
                利用規約
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="loggedin && hasOptionPayment"
            @click="moveToIndividualTermsForPayment"
          >
            <v-list-item-content>
              <v-list-item-title>
                個別規約
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="openAnnouncementDialog"
          >
            <v-list-item-content>
              <v-list-item-title>
                お知らせ<span v-if="existOpenAnnouncement">({{lastOpenAnnouncementDate}} 更新)</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="openHelpPage"
          >
            <v-list-item-content>
              <v-list-item-title>
                ヘルプ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="loggedin"
            @click="logOut"
          >
            <v-list-item-content>
              <v-list-item-title>
                ログアウト
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        </v-list>
      </v-menu>
  </v-app-bar>
  <v-main>
    <router-view  @loggedin="loadUser"
                  @login="clean"
    />
  </v-main>
</v-app>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import announcementDialog from '@/components/announcement/AnnouncementDialog.vue'
import organizerEditDialog from '@/components/OrganizerEditDialog.vue'
import assetLogo from '@/assets/TKPEventPlanner_logo.png'
import commonMixIn from "./mixins/common"
import {
  signout,
  getLastOpenAnnouncement,
  getOrganizerOptions,
  getSquareAccountType
} from './APIUtils.js'
export default {
  name: 'App',
  components: {
    'announcement_dialog': announcementDialog,
    'organizer_edit_dialog' : organizerEditDialog
  },
  mixins: [commonMixIn],
  computed: {
    isEnablePaymentSettings() {
      if(this.role === 'Owner' && this.squareAccountType === 'PERSONAL') return true

      return false
    },
    name() {
      return this.userInfo.lastName + " " + this.userInfo.firstName
    }
  },
  data() {
    return {
      logo: assetLogo,

      role: '',
      userInfo: {
        organizerId: '',
        affiliation: '',
        firstName:'',
        lastName:'',
      },
      loggedin:false,

      lastOpenAnnouncementDate: null,
      existOpenAnnouncement: false,
      squareAccountType: '',
      hasOptionZoom: false,
      hasOptionPayment: false
    }
  },
  methods: {
    openOrganizerUpdateDialog(){
      this.$refs.organizerEditDialog.openForEdit(this.role, this.userInfo)
    },
    onCompleteOrganizerEdit(userInfo) {
      this.userInfo.affiliation = userInfo.affiliation
      this.userInfo.firstName = userInfo.firstName
      this.userInfo.lastName = userInfo.lastName
    },
    openAnnouncementDialog() {
      this.$refs.announcementDialog.open()
    },
    loadUser(data) {
      this.userInfo.affiliation = data.affiliation
      this.userInfo.firstName = data.firstName
      this.userInfo.lastName = data.lastName
      this.userInfo.organizerId = data.id

      this.loggedin = true
      this.role = data.role
    },
    clean() {
      this.userInfo = {
        organizerId: '',
        affiliation: '',
        firstName:'',
        lastName:'',
      }
    },
    logOut() {
      signout()
      .then(res => {
          this.loggedin = false
          this.$router.push({path: '/Signout'})
      })
      .catch(err => {
          console.log("signout catch ", err)
      })
    },
    moveToEventSchedule(){
      if(this.loggedin) {
        this.moveTo('/EventSchedule')
      } else {
        this.moveTo('/')
      }
    },
    moveToSquareAccounts(){
      if(this.loggedin) {
        this.moveTo('/SquareAccounts')
      } else {
        this.moveTo('/')
      }
    },
    moveToZoomAccounts(){
      if(this.loggedin) {
        this.moveTo('/ZoomAccounts')
      } else {
        this.moveTo('/')
      }
    },
    moveToPaymentSetting(){
      this.moveTo('/PaymentSetting')
    },
    moveToCustomMailTemplates(){
      this.moveTo('/CustomMailTemplates')
    },
    moveToReturnPolicies(){
      this.moveTo('/ReturnPolicies')
    },
    moveToOperationLog(){
      this.moveTo('/OperationLog')
    },
    moveToTermsOfService(){
      this.moveTo('/TermsOfService')
    },
    moveToIndividualTermsForPayment(){
      this.moveTo('/IndividualTermsForPayment')
    },
    openHelpPage(){
      window.open('https://teachme.jp/44555/f/eventplanner/?page=1&sortKey=title&sortOrder=asc', '_blank');
    },
    setLastOpenAnnounceDate() {
      getLastOpenAnnouncement().then(res => {
        switch(res.status) {
          case 200:
            this.lastOpenAnnouncementDate = dayjs(res.data.openDateTime).format('YYYY/M/D')
            this.existOpenAnnouncement = true
            break
          case 204:
            this.lastOpenAnnouncementDate = null
            this.existOpenAnnouncement = false
            break
          default:
            console.log("status on announcement/lastOpen:", res.status)
            alert("エラーが発生しました")
            break
        }
      }).catch(err => {
        console.log("err", err)
      })
    },
    getSquareAccountType() {
      getSquareAccountType().then(res => {
        this.squareAccountType = res.data;
      }).catch(err => {
        console.log("err", err)
        alert('決済情報の確認に失敗しました。')
      })
    },
    getOrganizerOptions() {
      getOrganizerOptions().then(res => {
        this.hasOptionZoom = res.data.hasOptionZoom
        this.hasOptionPayment = res.data.hasOptionPayment
      })
    }
  },
  beforeCreate() {
    dayjs.locale('ja')
  },
  updated() {
    this.setLastOpenAnnounceDate()

    if(this.loggedin) {
      this.getSquareAccountType()
      this.getOrganizerOptions()
    }
  }
}
</script>

<style>
* {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
}
.headline {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  /* vuetify側でimportant指定されていたためimportant指定して上書く */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif !important;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
}
.headerLogo{
    width: 200px;
    margin-top: 5px;
}
.info_title {
  font-weight: bold;
  font-size: 1.3em;
}
@media screen and (max-width: 960px) {
      .headerLogo{
        width: 150px;
        margin-top: 5px;
    }
}
#compact-picker ul {
  padding-left: initial;
}
</style>
