export default {
  data() {
    return {
      ROLES: {
        OWNER: "Owner",
        MEMBER: "Member",
        VIEWER: "Viewer",
        OBSERVER: "Observer"
      },
    }
  },
  methods: {
    isOwner(role) {
      return (role == this.ROLES.OWNER)
    },
    isMember(role) {
      return (role == this.ROLES.MEMBER)
    },
    isViewer(role) {
      return (role == this.ROLES.VIEWER)
    },
    isObserver(role) {
      return (role == this.ROLES.OBSERVER)
    },
    isEditableRole(role) {
      return this.isOwner(role) || this.isMember(role)
    },
    moveTo(path, query) {
      if(this.$route.path != path) {
        if(query) {
          this.$router.push({
            path: path,
            query: query
          })
        } else {
          this.$router.push({path: path})
        }
      }
    }
  }
}
