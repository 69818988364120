import { render, staticRenderFns } from "./AnnouncementDialog.vue?vue&type=template&id=3bf46d8c&scoped=true"
import script from "./AnnouncementDialog.vue?vue&type=script&lang=js"
export * from "./AnnouncementDialog.vue?vue&type=script&lang=js"
import style0 from "./AnnouncementDialog.vue?vue&type=style&index=0&id=3bf46d8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf46d8c",
  null
  
)

export default component.exports