<template>
  <v-dialog persistent v-model="showSelf" max-width="500px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="modalHeader">
          <span class="headline">オーガナイザー情報編集</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="editedItem.affiliation" :rules="affiliationRules" label="会社名" required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editedItem.lastName" :rules="lastNameRules" label="姓" required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editedItem.firstName" :rules="firstNameRules" label="名" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  :label="`パスワードを変更`"
                  v-model="toggle"
                >
                </v-switch>
              </v-col>
              <v-col cols="12" v-if="toggle">
                <v-text-field v-model="editedItem.password" :rules="passwordRules" label="パスワード" required type="password"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="toggle">
                <v-text-field v-model="editedItem.passwordConfirm" :rules="passwordConfirmRules" label="パスワード（確認）" required type="password"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="isEditableRole(role)">
                <v-select
                  v-model="defaultEmailType"
                  :items="emailTypeItems"
                  item-value="value"
                  item-text="label"
                  label="イベント作成時に設定するメール形式"
                ></v-select>
              </v-col>
              <!-- リリース順の関係で一時非表示
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.defaultUrlTermsOfUse"
                  :rules="defaultUrlTermsOfUseRules"
                  label="イベント作成時に設定する利用規約のURL"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.defaultUrlIndividualTermsForVisitors"
                  :rules="defaultUrlIndividualTermsForVisitorsRules"
                  label="有料イベント作成時に設定する個別規約のURL"
                >
                </v-text-field>
              </v-col>
              -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="edit-b" @click="this.closeSelf">閉じる</v-btn>
          <v-btn color="error" :disabled="!valid" @click="submit">保存</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import commonMixIn from "../mixins/common"
import commonEmailMixIn from "../mixins/commonEmail"
import {
  getDefaultEmailType,
  getDefaultUrls,
  updateOrganizer
} from "../APIUtils";
export default {
  name: "OrganizerEditDialog",
  mixins: [
    commonMixIn,
    commonEmailMixIn
  ],
  data() {
    return {
      showSelf: false,
      toggle:false,
      valid: false,
      emailTypeItems: [],

      role: '',

      editedItem: {
        id: '',
        affiliation: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
        defaultUrlIndividualTermsForVisitors: '',
        defaultUrlTermsOfUse: ''
      },

      defaultEmailType: '',

      affiliationRules: [
        v => !!v || '会社名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      firstNameRules: [
        v => !!v || '名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      lastNameRules: [
        v => !!v || '姓は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      passwordRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英数字を混在させた文字列を、8文字以上55文字以下で設定してください'
      ],
      passwordConfirmRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英数字を混在させた文字列を、8文字以上55文字以下で設定してください',
        v => v == this.editedItem.password || '入力したパスワードが一致しません。'
      ],
      defaultUrlIndividualTermsForVisitorsRules: [
        v => !v || (v && v.length <= 255)|| '255文字以下で入力してください',
        v => !v || /^(https?:\/\/)?[\w%\-]+\.([\w%\-]+\.)*[a-zA-Z]{2,}(\/[\w%\-\.]+)*[/]?([\?#][\w/:%#\$&\?\(\)~\.=\+\-]+)?$/.test(v) || 'URLの形式で入力してください',
      ],
      defaultUrlTermsOfUseRules: [
        v => !v || (v && v.length <= 255)|| '255文字以下で入力してください',
        v => !v || /^(https?:\/\/)?[\w%\-]+\.([\w%\-]+\.)*[a-zA-Z]{2,}(\/[\w%\-\.]+)*[/]?([\?#][\w/:%#\$&\?\(\)~\.=\+\-]+)?$/.test(v) || 'URLの形式で入力してください',
      ],
    }
  },
  methods: {
    async openForEdit(role, userInfo) {
      this.role = role

      this.editedItem.id = userInfo.organizerId
      this.editedItem.affiliation = userInfo.affiliation
      this.editedItem.firstName = userInfo.firstName
      this.editedItem.lastName = userInfo.lastName

      this.toggle = false
      this.editedItem.password = ''
      this.editedItem.passwordConfirm = ''

      await getDefaultUrls()
        .then(res => {
          this.editedItem.defaultUrlIndividualTermsForVisitors = res.data.defaultUrlIndividualTermsForVisitors
          this.editedItem.defaultUrlTermsOfUse = res.data.defaultUrlTermsOfUse
        })
        .catch(err => {
          console.log('getDefaultUrls is failed.')
        })

      this.defaultEmailType = await getDefaultEmailType()
        .then(res => {
          return res.data
        })
        .catch(err => {
          console.log('getDefaultEmailType is failed.')
          return this.EMAIL_TYPE.HTML
        })

      this.showSelf = true
    },
    submit () {
      if (this.$refs.form.validate() && confirm('データを登録してもよろしいですか？')) {
        //Organizerの更新
        if (!this.toggle) this.editedItem.password = null
        updateOrganizer({
          data: {
            ...this.editedItem,
            defaultEmailType: this.defaultEmailType
          }
        }).then(res => {
          alert('アカウント情報を更新しました。')
          this.$emit('completeOrganizerEdit', {
            affiliation: this.editedItem.affiliation,
            firstName: this.editedItem.firstName,
            lastName: this.editedItem.lastName
          })
          this.closeSelf()
        }).catch(err => {
          console.log("err",err)
          alert('アカウント情報の更新に失敗しました。')
        })
      }
    },
    closeSelf() {
      this.showSelf = false
    },
  },
  mounted() {
    this.emailTypeItems = this.getEmailTypes()
  },
}
</script>

<style scoped>
.modalHeader{
  background-color: #263238;
  color: white;
  height: 70px;
  font-size:160%;
  font-weight:bold;
  filter:alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
</style>
