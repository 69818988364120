export default {
  data() {
    return {
      EMAIL_TYPE: {
        TEXT: "TEXT",
        HTML: "HTML"
      },
    }
  },
  methods: {
    getEmailTypeString(mailType) {
      switch(mailType) {
        case this.EMAIL_TYPE.TEXT :
          return "テキスト"
        case this.EMAIL_TYPE.HTML :
          return "HTML"
      }
    },
    getEmailTypes() {
      return [
        {
          label: "テキスト",
          value: this.EMAIL_TYPE.TEXT
        },
        {
          label: 'HTML',
          value: this.EMAIL_TYPE.HTML
        }
      ]
    }
  }
}
