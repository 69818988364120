import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import vuetify from './plugins/vuetify'
import CKEditor from 'ckeditor4-vue/dist/legacy.js'

// Polyfill
import 'core-js/stable'
import "regenerator-runtime/runtime"

Vue.config.productionTip = false
Vue.use(CKEditor)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


